/*
    Přidání štítku
*/
import React,{ Component, Fragment, useState } from 'react';
import {Mutation,Query, useMutation, useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_FINANCEMANAGEMENT_ITEM, GET_FINANCEMANAGEMENT_ITEM} from '../Queries/financeManagement';
import { withApollo } from 'react-apollo'; 
import FinanceManagementLib from '../Library/financeManagement';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import moment from 'moment';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import { Editor } from '@tinymce/tinymce-react';



const AddEditFinanceItem = (props) => {
    const financeManagementLib = new FinanceManagementLib(props);
    
	const [formData,setFormData] 		                    = useState({
        selectedLang:"cz",
        financeManagementItemID:props.selectedFinanceManagementItemID,
        currencyID:0,
        date:null,
        typeID:0,
        paymentTypeID:0,
        incomeOutputType:1,
        value:0,
        exchangeRate:0,
        bankAccountID:0,
        exchangeExchangeRate:0,
        exchangeCurrencyID:0,
        createOppositeIncomeOutput:1,
        includeToIncomeOutcome:1,
        note:"",
    });
    const [allLanguageMutations,setAllLanguageMutations]    = useState([]);
    
    const {loading:getLoading, error:getError, data:addData} = useQuery(GET_FINANCEMANAGEMENT_ITEM,{
        variables:{financeManagementItemID:formData.financeManagementItemID,orderBy:"financeManagementPriority ASC"},
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            setAllLanguageMutations(data.allLanguageMutations);            
            if(formData.financeManagementItemID != 0){
                setFormData({...formData,
                    currencyID:data.financeManagementItem.currencyID,
                    date:moment(data.financeManagementItem.date).toDate(),
                    typeID:data.financeManagementItem.typeID,
                    paymentTypeID:data.financeManagementItem.paymentTypeID,
                    incomeOutputType:data.financeManagementItem.incomeOutputType,
                    value:data.financeManagementItem.value,
                    exchangeRate:data.financeManagementItem.exchangeRate,
                    bankAccountID:data.financeManagementItem.bankAccountID,
                    exchangeExchangeRate:data.financeManagementItem.exchangeExchangeRate,
                    exchangeCurrencyID:data.financeManagementItem.exchangeCurrencyID,
                    includeToIncomeOutcome:data.financeManagementItem.includeToIncomeOutcome,
                    note:data.financeManagementItem.note
                });        
            }else{
                setFormData({...formData,
                    currencyID:props.currency.currencyID,
                    typeID:data.allTypes[0].typeID,
                    paymentTypeID:data.allPaymentTypes[0].paymentTypeID,
                    exchangeCurrencyID:props.currency.currencyID,
                    date:new Date()
                }); 
            }    
		}
    });
    
	const [addEditFinanceManagementItem, { data,loading:addLoading, error: addError }] = useMutation(ADD_EDIT_FINANCEMANAGEMENT_ITEM,{	
		onCompleted(data){
			if(formData.financeManagementItemID && formData.financeManagementItemID != 0){
                props.openCloseModal(false);
            }else{
                setFormData({...formData,financeManagementItemID:data.addEditFinanceManagementItem.financeManagementItemID});

                props.openCloseModal(false);
            }
		},update(cache,response) { 

            financeManagementLib.updateList(cache,response,formData.financeManagementItemID,props.listVariables);
            
            let notify = new Notification();

            if(formData.financeManagementItemID && formData.financeManagementItemID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);
		}
    });

    
    
    if(getError || addError){
        console.log(addError);
        var error = getError ? getError : addError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error);
    }

    return(
                
        <Modal
            className="Modal__Bootstrap modal-dialog modal-lg"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-code">{(formData.financeManagementItemID && formData.financeManagementItemID != 0 ? translate.t("EditCurrency") : translate.t("AddCurrency"))}</h4>
                    {allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => financeManagementLib.formHandle(e)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                <Fragment>
                                    <div className="row">                                                
                                        <div className="col-2">
                                            <div className="form-group">
                                                <label className="input-label">*Měna {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <select value={formData.currencyID} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,currencyID:e.target.value})}>
                                                    {addData && addData.allCurrencys.map((item,index) => (
                                                        <option key={index} value={item.currencyID}> {item.code} </option>
                                                    ))}
                                                </select>
                                            </div>                                                
                                        </div>                                     
                                        <div className="col-2">
                                            <div className="form-group">
                                                <label className="input-label">*Příjem/Výdej {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <select value={formData.incomeOutputType} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,incomeOutputType:e.target.value})}>
                                                <option value={1}> Příjem </option>
                                                <option value={2}> Výdej </option>
                                                </select>
                                            </div>                                                
                                        </div>                                           
                                        <div className="col-2">
                                            <div className="form-group">
                                                <label className="input-label">*Datum {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <div className="form-group">
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={formData.date} onChange={date => setFormData({...formData,date:date})} />
                                                </div> 
                                            </div>                                                
                                        </div>                                           
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">Do příjmu/výdajů tuto hodnotu</label>
                                                <div className="form-group">
                                                    <select value={formData.includeToIncomeOutcome} className="form-control" name="includeToIncomeOutcome" onChange={(e) => setFormData({...formData,includeToIncomeOutcome:e.target.value})}>
                                                        <option value={1}> Započítávat </option>
                                                        <option value={0}> Nezapočítávat </option>
                                                    </select>
                                                </div> 
                                            </div>                                                
                                        </div>  
                                    </div>
                                      
                                    <div className="row">  
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">*Druh {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <select value={formData.typeID} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,typeID:e.target.value})}>
                                                    {addData && addData.allTypes.map((item,index) => (
                                                        <option key={index} value={item.typeID}> {item.title} </option>
                                                    ))}
                                                </select>
                                            </div>                                                
                                        </div>                                           
                                        {financeManagementLib.isCurrectyExchangeSelected(addData,formData) &&    
                                            <Fragment>                                
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="input-label">*Převod {formData.incomeOutputType == 1 ? <span>z měny</span> : <span>na měnu</span>} {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                        <select value={formData.exchangeCurrencyID} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,exchangeCurrencyID:e.target.value})}>
                                                            {addData && addData.allCurrencys.map((item,index) => (
                                                                <option key={index} value={item.currencyID}> {item.code} </option>
                                                            ))}
                                                        </select>
                                                    </div>                                                
                                                </div>                                    
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="input-label">*Kurz převodu {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text bg-info text-white">1 {financeManagementLib.getCodeForCurrencyExchange("high",addData,formData)} = </div>
                                                            </div>                                                            
                                                            <input className="form-control" type="number" step="0.01" name="title" value={formData.exchangeExchangeRate} onChange={(e) => setFormData({...formData,exchangeExchangeRate:e.target.value})} />
                                                            <div className="input-group-append">
                                                                <div className="input-group-text bg-info text-white">{financeManagementLib.getCodeForCurrencyExchange("low",addData,formData)}</div>
                                                            </div> 
                                                        </div>
                                                    </div>                                                
                                                </div>                              
                                                <div className="col-3">
                                                    <div className="form-group">
                                                        <label className="input-label">Automaticky vytvořit {formData.incomeOutputType == 1 ? <span>výdej</span> : <span>příjem</span>} {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                        <select value={formData.createOppositeIncomeOutput} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,createOppositeIncomeOutput:e.target.value})}>
                                                            <option value={1}>Ano</option>
                                                            <option value={0}>Ne</option>
                                                        </select>
                                                    </div>                                                
                                                </div>
                                            </Fragment>  
                                        }
                                    </div>
                                    <div className="row"> 
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">*Platba {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <select value={formData.paymentTypeID} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,paymentTypeID:e.target.value})}>
                                                    {addData && addData.allPaymentTypes.map((item,index) => (
                                                        <option key={index} value={item.paymentTypeID}> {item.title} </option>
                                                    ))}
                                                </select>
                                            </div>                                                
                                        </div>   
                                        {formData.paymentTypeID == 2 &&                                   
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className="input-label">Na účet {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                    <select value={formData.bankAccountID} className="form-control" name="selectedLang" onChange={(e) => setFormData({...formData,bankAccountID:e.target.value})}>
                                                        
                                                        <option  value={0}> -- vyberte účet -- </option>
                                                        {addData && addData.allBankAccounts.map((item,index) => (
                                                            <option key={index} value={item.bankAccountID}> {item.title} </option>
                                                        ))}
                                                    </select>
                                                </div>                                                
                                            </div>    
                                        }
                                    </div>
                                    <div className="row"> 
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="input-label">*Částka {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <div className="form-group">
                                                    <input className="form-control" type="number" step="0.01" name="title" value={formData.value} onChange={(e) => setFormData({...formData,value:e.target.value})} />
                                                </div>
                                            </div>                                                
                                        </div>         
                                        {!financeManagementLib.isCzechCurrencySelected(addData,formData) &&    
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label className="input-label">*Kurz na CZK {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text bg-info text-white">1 {financeManagementLib.getCodeForCurrencyToCZK("high",addData,formData)} = </div>
                                                        </div>                                                            
                                                        <input className="form-control" type="number" step="0.01" name="exchangeRate" value={formData.exchangeRate} onChange={(e) => setFormData({...formData,exchangeRate:e.target.value})} />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text bg-info text-white">{financeManagementLib.getCodeForCurrencyToCZK("low",addData,formData)}</div>
                                                        </div> 
                                                    </div>
                                                </div>                                                
                                            </div> 
                                        }
                                    </div>
                                    <div className="row"> 
                                        <div className="col-12">  
                                            <div className="form-group">
                                                <label className="input-label">Poznámka {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                                <div className="form-group">
                                                    <textarea className="form-control" value={formData.note}  onChange={(e) => setFormData({...formData,note:e.target.value})}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment> 
                                :
                                <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => financeManagementLib.addEditFinanceManagementItem(addEditFinanceManagementItem,formData,addData)}>{(formData.financeManagementItemID && formData.financeManagementItemID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>    
    );


}

export default withApollo(AddEditFinanceItem);