/**
 *  komponenta pro štítky pro produkty
 */
import React,{ useState, Fragment } from 'react';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import OverviewLib from '../Library/overview';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import {PieChart, Pie, Cell,ResponsiveContainer,BarChart, Bar, ReferenceLine,LabelList} from 'recharts';
import moment from "moment";
import { GET_FINANCEMANAGEMENT_STATISTIC } from '../../FinanceManagement/Queries/financeManagement';


import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';


const Overview = (props) => {
		
    const [filtersForm,setFiltersForm]          = useState({
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
    });
    
    const [filterParams,setFiltersParams]       = useState({
        from: moment().startOf('month').format("YYYY-MM-DD"),
        to: moment().endOf('month').format("YYYY-MM-DD")
    });
    
    const setDateFrom = (date) => {
        setFiltersForm({...filtersForm,from:date});
        setFiltersParams({...filterParams,from:moment(date).format("YYYY-MM-DD")});
    }
    const setDateTo = (date) => {
        setFiltersForm({...filtersForm,to:date});
        setFiltersParams({...filterParams,to:moment(date).format("YYYY-MM-DD")});
    }

    const {loading, error:getError, data:itemsData,refetch} = useQuery(GET_FINANCEMANAGEMENT_STATISTIC,{
        variables:filterParams,
        fetchPolicy: 'cache-and-network'
    });
    const transformToBarChartData = (itemArray) => {
        let returnObj = {};
        for(const item of itemArray){
            returnObj[item.niceTitle] = item.value;
        }
        console.log(returnObj);
        return returnObj;
    }
    
   const dataBarChart = [
    {
      name: '', a: 240000, b: 2400, c: -2400,
    }
  ];



   const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
    ];
    

    const COLORS = ['#17a2b8', '#dc3545', '#ffc107'];
    
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,fill,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
        
        <text x={x} y={y} fill={fill} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" fontSize="10">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
        );
    };
         
    
    
    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError)
    }
    return(

        <div id="overview" className="whole-container overview" >
            <div className="row mb-4" >
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-body">  
                            <div className="d-flex align-items-center">
                                <h4 className="mb-0">Aktuální přehled financí </h4>
                                <div className="ml-auto mb-0 d-flex align-items-center">
                                    <span className="mr-2">Období</span> 
                                    <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.from} onChange={date => setDateFrom(date)} />
                                    <span className="mx-2">-</span> 
                                    <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.to} onChange={date => setDateTo(date)} popperPlacement="top-end"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
                
                {!loading && !error ?
                    <Fragment>
                        {itemsData.financeManagementStatistic && itemsData.financeManagementStatistic.map((item,index) => {
                            return(
                                <div className="col-md-12 col-lg-6 mb-4" key={index}>
                                    <div className="card shadow">
                                        <div className="card-body">                           
                                            <h4 className="header-title pb-3">{item.title}</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="chart-title mt-3">{item.income.title}</div>
                                                    <ResponsiveContainer width="100%" height={150}>
                                                        <PieChart>
                                                            <Pie data={item.income.items} labelLine={true} label={renderCustomizedLabel} innerRadius={0} outerRadius={50} fill="#8884d8" dataKey="value">
                                                                {
                                                                    item.income.items.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                                }
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                    <div className="chart-widget-list d-flex flex-column mt-3">
                                                        {item.income.items.map((paymentItem,paymentIndex) => {
                                                            return(
                                                                <p className=" d-flex align-items-center py-2 mb-0" key={paymentIndex}>
                                                                    <i className={"square mr-2 "} style={{backgroundColor:paymentItem.bgColor}}></i> {paymentItem.title}
                                                                    <span className="ml-auto">{paymentItem.value}</span>
                                                                </p>
                                                            )
                                                        })}
                                                        <p className=" d-flex align-items-center py-2 mb-0">
                                                            <strong>Celkem</strong>
                                                            <span className="ml-auto"><strong>{item.income.totalValue}</strong></span>
                                                        </p>
                                                        {item.income.items.map((paymentItem,paymentIndex) => {
                                                            return(
                                                                <>
                                                                {paymentItem.valueNotIncludedToIncomeOutcome > 0 &&
                                                                    <p className=" d-flex align-items-center py-2 mb-0" key={paymentIndex} title='Hodnoty uvedené jako "přesun" jsou částky, které se reálně nezapočítávají do příjmu ani výdeje, ale byly pouze přesouvány (například z hotovosi na účet)'>
                                                                        <i className={"square mr-2 "} style={{backgroundColor:paymentItem.bgColor}}></i> {paymentItem.title} (přesun)
                                                                        <span className="ml-auto">{paymentItem.valueNotIncludedToIncomeOutcome}</span>
                                                                    </p>
                                                                }
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="chart-title mt-3">{item.outcome.title}</div>
                                                    <ResponsiveContainer width="100%" height={150}>
                                                        <PieChart>
                                                            <Pie data={item.outcome.items} labelLine={true} label={renderCustomizedLabel} innerRadius={0} outerRadius={50} fill="#8884d8" dataKey="value">
                                                                {
                                                                    item.outcome.items.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                                }
                                                            </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                    <div className="chart-widget-list d-flex flex-column mt-3">
                                                        {item.outcome.items.map((paymentItem,paymentIndex) => {
                                                            return(
                                                                <p className=" d-flex align-items-center py-2 mb-0" key={paymentIndex}>
                                                                    <i className={"square mr-2 "} style={{backgroundColor:paymentItem.bgColor}}></i> {paymentItem.title}
                                                                    <span className="ml-auto">{paymentItem.value}</span>
                                                                </p>
                                                            )
                                                        })}
                                                        <p className=" d-flex align-items-center py-2 mb-0">
                                                            <strong>Celkem</strong>
                                                            <span className="ml-auto"><strong>{item.outcome.totalValue}</strong></span>
                                                        </p>
                                                        {item.outcome.items.map((paymentItem,paymentIndex) => {
                                                            return(
                                                                <>
                                                                {paymentItem.valueNotIncludedToIncomeOutcome > 0 &&
                                                                    <p className=" d-flex align-items-center py-2 mb-0" key={paymentIndex} title='Hodnoty uvedené jako "přesun" jsou částky, které se reálně nezapočítávají do příjmu ani výdeje, ale byly pouze přesouvány (například z hotovosi na účet)'>
                                                                        <i className={"square mr-2 "} style={{backgroundColor:paymentItem.bgColor}}></i> {paymentItem.title} (přesun)
                                                                        <span className="ml-auto">{paymentItem.valueNotIncludedToIncomeOutcome}</span>
                                                                    </p>
                                                                }
                                                                </>
                                                            )
                                                        })}
                                                        {
                                                            <p className=" d-flex align-items-center py-2 mb-0">
                                                                <strong>Celkem s přesuny</strong>
                                                                <span className="ml-auto"><strong>{item.outcome.totalValueWithNotIncludedItems}</strong></span>
                                                            </p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="chart-title mt-3">{item.result.title}</div>
                                                    <ResponsiveContainer width="100%" height={150}>
                                                        <BarChart
                                                            data={[transformToBarChartData(item.result.items)]}
                                                            margin={{
                                                            top: 20, right: 30, left: 20, bottom: 20,
                                                            }}
                                                        >
                                                            <ReferenceLine y={0} stroke="#000" />
                                                            {item.result.items.map((item,index) => {
                                                                return(<Bar key={index} dataKey={item.niceTitle} fill={COLORS[index]}><LabelList dataKey={item.niceTitle} position="top" fontSize="10" /></Bar>)
                                                            })}
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                    <div className="chart-widget-list d-flex flex-column mt-3">
                                                        {item.result.items.map((paymentItem,paymentIndex) => {
                                                            return(
                                                                <p className=" d-flex align-items-center py-2 mb-0" key={paymentIndex}>
                                                                    <i className={"square mr-2 "} style={{backgroundColor:paymentItem.bgColor}}></i> {paymentItem.title}
                                                                    <span className="ml-auto">{paymentItem.value}</span>
                                                                </p>
                                                            )
                                                        })}
                                                        <p className=" d-flex align-items-center py-2 mb-0">
                                                            <strong>Celkem</strong>
                                                            <span className="ml-auto"><strong>{item.result.totalValue}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            )
                        })}
                    </Fragment>
                    
                    :
                    <div className="d-flex w-100 align-items-center justify-content-center m-5">
                        {loading &&  <Loading />}
                        {error &&  <Error text={error} />}
                    </div>
                }
            </div> 
        </div>
    );

}

export default withApollo(Overview);