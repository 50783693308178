/**
 *  komponenta pro štítky pro produkty
 */
import React,{useState, useEffect, useRef } from 'react';
import { withApollo, useQuery } from 'react-apollo';
import {GET_INITIALDATA} from '../Queries/financeManagement';
import CurrencyManagement from './CurrencyManagement';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';

import moment from "moment";



const FinanceManagementWprapper = (props) => {

    const typeDropdownRef           = useRef();
    const paymentDropdownRef        = useRef();
    const incomeOutputDropdownRef   = useRef();

    const [selectedTab,setSelectedTab]          = useState({
        currencyID:0,
        code:""
    });
    const [searchTimeoutID,setSearchTimeoutID]  = useState(null);

    const [dropdowns,setDropdowns]              = useState({
        showType:false,
        showPayment:false,
        showIncomeOutput:false
    });
    
    const [filtersForm,setFiltersForm]          = useState({
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
        type:[],
        paymentType:[],
        searchText:"",
        incomeOutput:[
            {
                incomeOutputType:1,
                title:"Příjem",
                checked:false
            },
            {
                incomeOutputType:2,
                title:"Výdej",
                checked:false
            }
        ],
    });
    
    const [filterParams,setFiltersParams]       = useState({
        from: moment().startOf('month').format("YYYY-MM-DD"),
        to: moment().endOf('month').format("YYYY-MM-DD"),
        searchText:"",
        typeIDs:[],
        paymentTypeIDs:[],        
        incomeOutputIDs:[],
        offset:0,
        limit:100
    });

    const globalClickListener = (e) => {
        
        if(typeDropdownRef.current.contains(e.target) || paymentDropdownRef.current.contains(e.target) || incomeOutputDropdownRef.current.contains(e.target)){
            return;
        }
        openCloseDropdown(false);
    }

    useEffect(() => {
        document.addEventListener('click', globalClickListener)
        return () => {
            document.removeEventListener('click', globalClickListener);
        };
    })


    const {loading, error:getError, data:initialData} = useQuery(GET_INITIALDATA,{
        variables:{
            orderBy:"financeManagementPriority ASC"
        },
        onCompleted: (data) => {
            if(data && data.allCurrencys){
                setSelectedTab(data.allCurrencys[0]);
            }

            data.allTypes.map((item) => item.checked = false);            
            data.allPaymentTypes.map((item) => item.checked = false);
            
            setFiltersForm({...filtersForm,type:data.allTypes,paymentType:data.allPaymentTypes});

        }
    });
    
    const selectTab = (e,tab) => {
        e.preventDefault();
        setSelectedTab(tab);        
    }

    const openCloseDropdown = (dropdownControllParam) => {
        let dropdownsObj = {
            showType:false,
            showPayment:false,
            showIncomeOutput:false 
        }
        if(dropdownControllParam && !dropdowns[dropdownControllParam]){
            dropdownsObj[dropdownControllParam] = true;
        }
        setDropdowns(dropdownsObj);
    }
        
    const checkFilter = (e,value,filterType,idName) => {

        var checked = e.target.checked;


        var arr     = [...filtersForm[filterType]];
        var idsArr  = [];

        for(let item of arr){
            if(value == item[idName]){
                item.checked = checked;
            }

            if(item.checked){
                idsArr.push(parseInt(item[idName]));
            }
        }
        setFiltersForm({...filtersForm,[filterType]:arr});
        setFiltersParams({...filterParams,[filterType + "IDs"]:idsArr});
  
    }
    const setDateFrom = (date) => {
        setFiltersForm({...filtersForm,from:date});
        setFiltersParams({...filterParams,from:moment(date).format("YYYY-MM-DD")});
    }
    const setDateTo = (date) => {
        setFiltersForm({...filtersForm,to:date});
        setFiltersParams({...filterParams,to:moment(date).format("YYYY-MM-DD")});
    }

    const setSearchText = (text) => {
        setFiltersForm({...filtersForm,searchText:text});

        clearTimeout(searchTimeoutID);
        const timeoutID = setTimeout(function(){
            setFiltersParams({...filterParams,searchText:text});
        },1000);
        setSearchTimeoutID(timeoutID);

    }

    return(

        <div id="settings" className="whole-container" >
            
            <div className="row mb-5" >
                <div className="col-sm one">
                    <div className="card main">
                            
                        <div className="card-header d-flex align-items-center header-filters ">
                            
                            <div className="mr-1">Od:</div> 
                            <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.from} onChange={date => setDateFrom(date)} />
                            <div className="ml-2 mr-1">Do:</div> 
                            <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.to} onChange={date => setDateTo(date)} popperPlacement="top-end"/>
                            <div className="divider mx-2"></div>

                            <div className="dropdown mr-2" ref={typeDropdownRef}>
                                <button className="btn btn-secondary dropdown-toggle" type="button" onClick={() => {openCloseDropdown("showType")}}  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Filtrovat druh
                                </button>
                                <div className={"dropdown-menu " + (dropdowns.showType ? "show" : "")} aria-labelledby="dropdownMenuButton">
                                    {filtersForm.type.length > 0 && filtersForm.type.map((item,index) => {
                                        return(
                                            <div className="dropdown-item" key={index}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.checked} id={"type" + item.typeID} onChange={(e) => checkFilter(e,item.typeID,"type","typeID")}/>
                                                    <label className="form-check-label" htmlFor={"type" + item.typeID}>
                                                        {item.title}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="dropdown mr-2" ref={paymentDropdownRef}>
                                <button className="btn btn-secondary dropdown-toggle" type="button" onClick={() => {openCloseDropdown("showPayment")}} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Filtrovat platbu
                                </button>
                                <div className={"dropdown-menu " + (dropdowns.showPayment ? "show" : "")} aria-labelledby="dropdownMenuButton">
                                    {filtersForm.paymentType.length > 0 && filtersForm.paymentType.map((item,index) => {
                                            return(
                                            <div className="dropdown-item" key={index}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.checked} id={"paymentType" + item.paymentTypeID} onChange={(e) => checkFilter(e,item.paymentTypeID,"paymentType","paymentTypeID")}/>
                                                    <label className="form-check-label" htmlFor={"paymentType" + item.paymentTypeID}>
                                                        {item.title}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="dropdown" ref={incomeOutputDropdownRef}>
                                <button className="btn btn-secondary dropdown-toggle" type="button" onClick={() => {openCloseDropdown("showIncomeOutput")}} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Filtrovat příjem/výdej
                                </button>
                                <div className={"dropdown-menu " + (dropdowns.showIncomeOutput ? "show" : "")} aria-labelledby="dropdownMenuButton">
                                    {filtersForm.incomeOutput.length > 0 && filtersForm.incomeOutput.map((item,index) => {
                                            return(
                                            <div className="dropdown-item" key={index}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.checked} id={"incomeOutput" + item.incomeOutputType} onChange={(e) => checkFilter(e,item.incomeOutputType,"incomeOutput","incomeOutputType")}/>
                                                    <label className="form-check-label" htmlFor={"incomeOutput" + item.incomeOutputType}>
                                                        {item.title}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="divider mx-2"></div>
                            <input type="text" className="form-control " placeholder="Vyhledat v poznámce" value={filtersForm.searchText} onChange={e => setSearchText(e.target.value)} />
                            
                        </div>
                        <div className="card-body">

                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {initialData && initialData.allCurrencys.map((item,index) => {
                                    return(

                                        <li className="nav-item" key={index}>
                                            <a onClick={(e) => selectTab(e,item)} className={"nav-link " + (selectedTab.currencyID == item.currencyID ? "active" : "")} id="home-tab" data-toggle="tab" href="" role="tab" aria-controls="home" aria-selected="true">{item.code}</a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane show active">
                                    {selectedTab.currencyID && 
                                        <CurrencyManagement currency={selectedTab} filters={filterParams} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 	
        </div>
    );

}

export default withApollo(FinanceManagementWprapper);