import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    notifications: [Notification]
    isAdminLogin: Boolean!
  }

  type Notification {
    error: Boolean
    success: Boolean
    text: String!
  }


`;

export const resolvers = {};