/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit type
 */
export const ADD_EDIT_TYPE = gql`
    mutation AddEditType($typeID:ID!,$title:String,$isCurrencyExchange:Int){
        addEditType(typeID:$typeID,title:$title,isCurrencyExchange:$isCurrencyExchange){
            typeID
            title
            isCurrencyExchange
        }
    }
`;


/**
 *  Type data
 */

export const GET_TYPE = gql`
    query Type($typeID: ID!){
        type(typeID:$typeID){
            typeID
            title
            isCurrencyExchange             
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých typeů
 */
export const GET_TYPES = gql`
    query AllTypes{
        allTypes{            
            typeID
            title
            isCurrencyExchange  
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_TYPES = gql`
    mutation deleteTypes($typeIDs:ID!) {
        deleteTypes(typeIDs:$typeIDs)
    }
`;


