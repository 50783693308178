/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_LINK,GET_LINKS} from '../Queries/index';
import Notification from '../../../Library/notification';
import {SERVER_URL} from '../../../Config/index';
const moment             = require("moment");

class Link {

    constructor(parent){
        this.parent = parent;
    }


    
    /**
     * Přidání/Editace
     * @param {*} addLink funkce z apollo, pomocí které se posílají data na server
     */

    addLink = async (addEditLink,callback = null) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   
        
            
                        
        let langs = [];
        for(const lang of data.langs){

            langs.push({
                name:           lang.name,
                ownUrl:         lang.ownUrl,
                description:    lang.description,
                metaTitle:      lang.metaTitle,
                metaKeywords:   lang.metaKeywords,
                metaDescription:lang.metaDescription,
                lang:           lang.lang
            });
        }
        

        addEditLink({
            variables:{
                linkID:data.linkID,
                parentID:data.parentID,
                active:parseInt(data.active),
                inHeader:parseInt(data.inHeader),
                inFooter:parseInt(data.inFooter),
                langs
            }
        }).then(responseData => {
            if(callback){
                callback(true,responseData);
            }
        }, errorData =>{
            if(callback){
                callback(false,errorData);
            }
        });
                  
               
    } 

    
    /**
     * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
     */

    async getData(){

        var linkID = 0;
        if(this.parent.props.selectedLinkID)linkID = this.parent.props.selectedLinkID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_LINK,
            errorPolicy:"all",
            variables:{linkID:linkID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.linkWithLangs){    
                
                let langs = this.completeLangsArray(data.data.linkWithLangs.langs,data.data.allLanguageMutations);    
                
                this.parent.setState({
                    apolloError:"",
                    selectedLinkID:linkID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        linkID:     data.data.linkWithLangs.linkID,
                        parentID:   data.data.linkWithLangs.parentID,
                        active:     data.data.linkWithLangs.active,
                        inHeader:   data.data.linkWithLangs.inHeader,
                        inFooter:   data.data.linkWithLangs.inFooter,
                        langs:      langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(linkID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);     
            this.parent.setState({
                selectedLinkID: linkID,
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",                    
                    description:"",
                    metaTitle:"",
                    metaKeywords:"",
                    metaDescription:"",
                    ownUrl:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allLinks } = cache.readQuery({ query: GET_LINKS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditLink,...allLinks]   ;
            
            cache.writeQuery({ 
                query:GET_LINKS,
                variables:variables,
                data:{
                    allLinks: arr
                } 
            });

        }else{

            var arr = [...allLinks];
            
            arr.forEach((item,index) => {
                
                if(item.linkID == response.data.addEditLink.linkID){
                    arr[index].linkID       = response.data.addEditLink.linkID;
                    arr[index].title           = response.data.addEditLink.title;
                    arr[index].lang            = response.data.addEditLink.lang;

                    if(response.data.addEditLink.photo){
                        arr[index].photo = response.data.addEditLink.photo;
                    }
                }

            });
            cache.writeQuery({ 
                query:GET_LINKS,
                variables:variables,
                data:{
                    allLinks: arr
                } 
            });
        }
    }


    /**
     * Otevře obrázke, zpracuje a zobrazí jeho náhled
     * @param {*} e 
     */

    openImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteLinks.split(",");

        const { allLinks } = cache.readQuery({ query: GET_LINKS ,variables});
        var arr = [...allLinks];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.linkID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_LINKS,
            variables,
            data:{
                allLinks: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    /**
     * Vraci počet znaků zadané proměnné v objektu jazykových mutací lang
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns počet znaků
     */
    getCharCount = (paramName) => {        
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[paramName].length;
            }
        }
        return 0;
    }

    

    /**
     * Nastaví datum publikování
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    setPublishDate(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang.publishDate = date;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Zjisti, jestli byl již daný článek publikován
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    linkPublished(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang.published){
                    return true;
                }else{
                    return false;
                }
            }
        }
        return false;
    }

    
}

export default Link;