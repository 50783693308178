/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit finance item
 */
export const ADD_EDIT_FINANCEMANAGEMENT_ITEM = gql`
    mutation AddEditFinanceManagementItem(
        $financeManagementItemID:ID!,
        $currencyID:ID,
        $date:String,
        $typeID:ID,
        $paymentTypeID:ID,
        $incomeOutputType:ID,
        $value:Float,
        $exchangeRate:Float,
        $bankAccountID:ID,
        $exchangeExchangeRate:Float,
        $exchangeCurrencyID:ID,
        $includeToIncomeOutcome:Int,
        $note:String
        $createOppositeIncomeOutput:Int){

            addEditFinanceManagementItem(
                financeManagementItemID:$financeManagementItemID,
                currencyID:$currencyID,
                date:$date,
                typeID:$typeID,
                paymentTypeID:$paymentTypeID,
                incomeOutputType:$incomeOutputType,
                value:$value,
                exchangeRate:$exchangeRate,
                bankAccountID:$bankAccountID,
                exchangeExchangeRate:$exchangeExchangeRate,
                exchangeCurrencyID:$exchangeCurrencyID,
                includeToIncomeOutcome:$includeToIncomeOutcome,
                note:$note,
                createOppositeIncomeOutput:$createOppositeIncomeOutput){

                    financeManagementItemID
                    currencyID
                    date
                    type{
                        title      
                        isCurrencyExchange          
                    }
                    paymentType{
                        paymentTypeID
                        title
                    }
                    incomeOutputType
                    value
                    exchangeRate
                    bankAccount{
                        title
                        accountPrefix
                        accountNumber
                        bankNumber
                    }
                    exchangeExchangeRate
                    exchangeCurrency{
                        currencyID
                        code
                    }
                    note
            }
    }
`;

/**
 *  Finance row data
 */

export const GET_FINANCEMANAGEMENT_ITEM = gql`
    query FinanceManagementItem($financeManagementItemID: ID!,$orderBy:String){
        financeManagementItem(financeManagementItemID:$financeManagementItemID){
            financeManagementItemID
            currencyID
            date
            typeID
            paymentTypeID
            incomeOutputType
            value
            exchangeRate
            bankAccountID
            exchangeExchangeRate
            includeToIncomeOutcome
            exchangeCurrencyID
            note          
        }
        allCurrencys(orderBy:$orderBy){            
            currencyID
            code
            priority
        }
        allBankAccounts{
            bankAccountID
            title
            accountPrefix
            accountNumber
            bankNumber
        }
        allTypes{            
            typeID
            title
            isCurrencyExchange  
        }
        allPaymentTypes{            
            paymentTypeID
            title              
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Inicializační data pro seznam financí
 */
export const GET_INITIALDATA = gql`
    query InitialData($orderBy:String){
        allCurrencys(orderBy:$orderBy){            
            currencyID
            code
            priority
        }
        allTypes{            
            typeID
            title
            isCurrencyExchange  
        }
        allPaymentTypes{            
            paymentTypeID
            title              
        }
    }
`;

/**
 *  Seznam všech filtrovaných položek financí 
 */
export const GET_FINANCEMANAGEMENT_ITEMS = gql`
    query FinanceManagementItems($filters:FiltersInput,$currencyID:ID){
        financeManagementItems(filters:$filters,currencyID:$currencyID){            
            financeManagementItemID
            currencyID
            includeToIncomeOutcome
            date
            type{
                title 
                isCurrencyExchange               
            }
            paymentType{
                paymentTypeID
                title
            }
            incomeOutputType
            value
            exchangeRate
            bankAccount{
                title
                accountPrefix
                accountNumber
                bankNumber
            }
            exchangeExchangeRate
            exchangeCurrency{
                currencyID
                code
            }
            note
        }
        financeManagementItemsCount(filters:$filters,currencyID:$currencyID)
        financeManagementItemsRecap(filters:$filters,currencyID:$currencyID){
            incomeSum
            outcomeSum
            resultSum            
            resultCashSum
			resultBankAccSum
        }
    }
`;
/**
 *  Seznam všech filtrovaných položek financí 
 */
export const GET_FINANCEMANAGEMENT_ITEMS_RECAP = gql`
    query FinanceManagementItemsRecap($filters:FiltersInput,$currencyID:ID){
        financeManagementItemsRecap(filters:$filters,currencyID:$currencyID){
            incomeSum
            outcomeSum
            resultSum
        }
    }
`;

/**
 *  Seznam všech filtrovaných položek financí 
 */
export const GET_FINANCEMANAGEMENT_STATISTIC = gql`
    query FinanceManagementStatistic($filters:OverviewFiltersInput,$from:String,$to:String){
        financeManagementStatistic(filters:$filters,from:$from,to:$to){            
            title            
            income{
                title
                totalValue
                totalValueWithNotIncludedItems
                items{
                    title
                    niceTitle
                    value
                    valueNotIncludedToIncomeOutcome
                    paymentTypeID
                    bgColor
                }
            }
            outcome{
                title
                totalValue
                totalValueWithNotIncludedItems
                items{
                    title
                    niceTitle
                    value
                    valueNotIncludedToIncomeOutcome
                    paymentTypeID
                    bgColor
                }
            }
            result{
                title
                totalValue
                totalValueWithNotIncludedItems
                items{
                    title
                    niceTitle
                    value
                    valueNotIncludedToIncomeOutcome
                    paymentTypeID
                    bgColor
                }
            }
        }
    }
`;

/**
 * Smazání položek.
 */
export const DELETE_FINANCEMANAGEMENT_ITEMS = gql`
    mutation DeleteFinanceManagementItems($financeManagementItemIDs:ID!) {
        deleteFinanceManagementItems(financeManagementItemIDs:$financeManagementItemIDs)
    }
`;


/**
 * úprava priority článku
 */
export const UPDATE_RECOMMENDED_ARTICLE_PRIORITY = gql`

    mutation UpdateCurrencyPriority($currencyID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateCurrencyPriority(currencyID:$currencyID,fromIndex:$fromIndex,toIndex:$toIndex)
    }

`;



