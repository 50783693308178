/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment, useState } from 'react';
import AddEditType from './AddEditType';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_TYPES,DELETE_TYPES} from '../Queries/type';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import TypeLib from '../Library/type';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';


const Types = (props) => {
		
	const [selectedTypes,setSelectedTypes] 		    = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showAdd,setShowAdd] 			            = useState(false);
	const [selectedTypeID,setSelectedTypeID] 		= useState(0);

    const {loading, error:getError, data:typeData} = useQuery(GET_TYPES);
    
	const [deleteType, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_TYPES,{	
		update(cache,response) {            
            let type = new TypeLib(this);
            type.updateAfterDelete(cache, response);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const openCloseModal = (open,typeID) => {
        setSelectedTypeID(typeID);
        setShowAdd(open);
    }

    /**
     * smazání štítků
     * @param {*} deleteTypes funkce, která posílá data na server
     * @param {*} typeID ID typeu
     */
    
    const initiateDeleteType = (e,typeID) => {

        var checked = e.target.checked;
        var arr = [...selectedTypes];

        if(!checked){
            for(let i in selectedTypes){
                if(selectedTypes[i] == typeID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [typeID,...arr];
        }

        setSelectedTypes(arr);
  
    }

    const delType = (action) => {

        if(action){

            let typeIDs = selectedTypes.join(",");

            deleteType({
                variables:{
                    typeIDs: typeIDs
                }
            })
        }
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedTypes.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,this.props.client);
        }
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("TypeList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!loading && !error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("TitleList")}</div>
                                                <div className="text-center">{translate.t("IsExchange")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {typeData.allTypes && typeData.allTypes.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedTypes){
                                                    if(selectedTypes[i] == item.typeID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.title}</div>
                                                        <div className="text-center">{item.isCurrencyExchange ? translate.t("Yes") : translate.t("No")}</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true,item.typeID)} className="edit-icon" src={edit} /> 
                                                            <input className="delete-checked" type="checkbox" name="deleteType[]" checked={checked} onChange = {(e) => initiateDeleteType(e,item.typeID)} />
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {typeData.allTypes && typeData.allTypes.length == 0 &&
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {loading &&  <Loading />}
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditType openCloseModal={openCloseModal} selectedTypeID={selectedTypeID} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delType} /> }	
        </div>
    );

}

export default withApollo(Types);