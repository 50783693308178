/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_TYPE,GET_TYPES} from '../Queries/type';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
const moment             = require("moment");

class Type {

    constructor(props){
        this.props = props;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addType funkce z apollo, pomocí které se posílají data na server
     */

    addType = async (addType,formData,callback = null) => {

        let notify = new Notification();   
        
        if(formData.title != ""){                

                addType({
                    variables:{
                        typeID:formData.typeID,
                        title:formData.title,
                        isCurrencyExchange:parseInt(formData.isCurrencyExchange),
                    }
                }).then(responseData => {
                    if(callback){
                        callback(true,responseData);
                    }
                }, errorData =>{
                    if(callback){
                        callback(false,errorData);
                    }
                });
        }else{
            notify.setNotification(null,translate.t("TitleNotFilled"),false,true,this.props.client);
        }
               
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID){

        const { allTypes } = cache.readQuery({ query: GET_TYPES});
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditType,...allTypes]   ;
            
            cache.writeQuery({ 
                query:GET_TYPES,
                data:{
                    allTypes: arr
                } 
            });

        }else{

            var arr = [...allTypes];
            
            arr.forEach((item,index) => {
                
                if(item.typeID == response.data.addEditType.typeID){
                    arr[index].typeID               = response.data.addEditType.typeID;
                    arr[index].title                = response.data.addEditType.title;
                    arr[index].isCurrencyExchange   = response.data.addEditType.isCurrencyExchange;
                }

            });
            cache.writeQuery({ 
                query:GET_TYPES,
                data:{
                    allTypes: arr
                } 
            });
        }
    }


    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteTypes.split(",");

        const { allTypes } = cache.readQuery({ query: GET_TYPES ,variables});
        var arr = [...allTypes];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.typeID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_TYPES,
            variables,
            data:{
                allTypes: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Function} setFormData funkce nastavující data formulare
     */
    
    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        setFormData({...formData,[n]: v});
              
    }

    
}

export default Type;