/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment, useState, useEffect } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_FINANCEMANAGEMENT_ITEMS,DELETE_FINANCEMANAGEMENT_ITEMS} from '../Queries/financeManagement';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import moment from "moment";
import ReactTooltip from 'react-tooltip'
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import AddEditFinanceItem from './AddEditFinanceItem';
import CurrencyRecap from './CurrencyRecap';
import FinanceManagementLib from '../Library/financeManagement';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Notification from '../../../Library/notification';


const FinanceManagementCurrency = (props) => {

	const [selectedFinanceManagementItemID,setSelectedfinanceManagementItemID] 		= useState(0);
	const [selectedItems,setSelectedItems]          = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
    const [showAdd,setShowAdd]                      = useState(false);
    

    const listVariables = {
        filters:props.filters,
        currencyID:props.currency.currencyID
    }

    const {loading, error:getError, data:itemsData,refetch,fetchMore} = useQuery(GET_FINANCEMANAGEMENT_ITEMS,{
        variables:listVariables,
        fetchPolicy: 'cache-and-network'
    });
    
	const [deleteFinanceManagementItems, { data,loading: deleteLoading, error: deleteError  }] = useMutation(DELETE_FINANCEMANAGEMENT_ITEMS,{	
		update(cache,response) {       
            let financeManagement = new FinanceManagementLib(props);
            financeManagement.updateAfterDelete(cache, response,listVariables);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            
            setSelectedItems([]);
            setShowDeleteNotifi(false);
        },
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });
    
    useBottomScrollListener(() => {
        if(!loading && itemsData.financeManagementItemsCount > itemsData.financeManagementItems.length){
            fetchMore({
                variables: {
                  filters: {...listVariables.filters,offset:itemsData.financeManagementItems.length}
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    financeManagementItems: [...prev.financeManagementItems, ...fetchMoreResult.financeManagementItems]
                  });
                }
              })
        }
    },150, 10);

    const openCloseModal = (open,financeManagementItemID) => {
        setSelectedfinanceManagementItemID(financeManagementItemID);
        setShowAdd(open);
    }
    
    /**
     * smazání 
     * @param {*} deleteTypes funkce, která posílá data na server
     * @param {*} financeManagementItemID ID typeu
     */
    
    const deleteCheckboxChanged = (e,financeManagementItemID) => {

        var checked = e.target.checked;
        var arr = [...selectedItems];

        if(!checked){
            for(let i in selectedItems){
                if(selectedItems[i] == financeManagementItemID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [financeManagementItemID,...arr];
        }

        setSelectedItems(arr);
  
    }
    
    const delFinanceManagementItems = (action) => {

        if(action){
            let financeManagementItemIDs = selectedItems.join(",");

            deleteFinanceManagementItems({
                variables:{
                    financeManagementItemIDs: financeManagementItemIDs
                }
            })
        }
        //this.setState(INITIAL_STATE);
    }
    const showDelNotifi = () => {

        if(selectedItems.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,this.props.client);
        }
    }
        

    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(
        <Fragment>
            <div className="" >
                {!error ?
                    <div>
                        <div className="mb-2 d-flex">
                            {itemsData &&
                                <Fragment>
                                    <span className="badge badge-primary p-2 mr-2">Příjmy celkem: <strong>{itemsData.financeManagementItemsRecap.incomeSum} {props.currency.code}</strong></span>
                                    <span className="badge badge-dark p-2 mr-2">Výdaje celkem: <strong>{itemsData.financeManagementItemsRecap.outcomeSum} {props.currency.code}</strong></span>
                                    <span className="badge badge-info p-2 mr-2">Zůstatek celkem (hotovost): <strong>{itemsData.financeManagementItemsRecap.resultCashSum} {props.currency.code}</strong></span> 
                                    <span className="badge badge-danger p-2 mr-2">Zůstatek celkem (účet): <strong>{itemsData.financeManagementItemsRecap.resultBankAccSum} {props.currency.code}</strong></span> 
                                    <span className="badge badge-warning p-2">Zůstatek celkem: <strong>{itemsData.financeManagementItemsRecap.resultSum} {props.currency.code}</strong></span> 
                                </Fragment>
                            }
                            
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                            
                        </div>
                        <div className="data-list financeManagementItem-management-list">
                            
                            <div className="data-list-item header">
                                <div className="data-list-item-content">
                                    <div>Datum</div>
                                    <div className="text-center">Druh</div>
                                    <div className="text-center">Platba</div>
                                    <div className="text-center">Příjem</div>
                                    <div className="text-center">Výdej</div>
                                    <div className="text-center">Započítávat do příjmu/výdajů</div>
                                    {props.currency.code != "CZK" && 
                                        <div className="text-center">Kurz na CZK</div>
                                    }
                                    <div className="text-center note">Poznámka</div>
                                    <div className="text-right">Možnosti</div>
                                </div>
                            </div>
                            <Fragment>
                                {itemsData && itemsData.financeManagementItems && itemsData.financeManagementItems.map((item,index) => {
                                            
                                    var checked = false;
                                    for(let i in selectedItems){
                                        if(selectedItems[i] == item.financeManagementItemID)checked = true;
                                    }                                                       
                                    return (
                                    
                                        <div key={index} className="data-list-item-content">
                                            <div className="">{moment(item.date).format("D.M.YYYY")}</div>
                                            <div className="text-center ">
                                                {item.type.title}        
                                                {item.paymentType.paymentTypeID == 2 && item.bankAccountID &&
                                                    <div>
                                                        ({item.bankAccount.title} - {(item.bankAccount.accountPrefix ? item.bankAccount.accountPrefix + "-" : "") + item.bankAccount.accountNumber + (item.bankAccount.bankNumber ? "/" + item.bankAccount.bankNumber : "")})
                                                    </div>
                                                }                                        
                                            </div>
                                            <div className="text-center 1">{item.paymentType.title}</div>
                                            <div className="text-center ">
                                                {item.incomeOutputType == 1 && 
                                                    <Fragment>
                                                        <strong>{item.value} {props.currency.code}</strong>
                                                        {item.type.isCurrencyExchange == 1 && 
                                                            <div>(z {item.exchangeCurrency.code}, kurz: {item.exchangeExchangeRate})</div>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className="text-center ">
                                                {item.incomeOutputType == 2 && 
                                                    <Fragment>
                                                        <strong>{item.value} {props.currency.code}</strong>
                                                        {item.type.isCurrencyExchange == 1 && 
                                                            <div>(na {item.exchangeCurrency.code}, kurz: {item.exchangeExchangeRate})</div>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className="text-center">
                                                {item.includeToIncomeOutcome == 1 ? "Ano" : "Ne"}
                                            </div>
                                            {props.currency.code != "CZK" && 
                                                <div className="text-center">
                                                    {item.exchangeRate != 0 ? 
                                                        <span>{item.exchangeRate}</span>
                                                        :
                                                        <strong className="text-danger">Nenastaveno</strong>
                                                    }
                                                </div>
                                            }
                                            <div className="text-center note text-truncate cursor-default"><a data-tip data-html={true}  data-for={"mItem" + item.financeManagementItemID}>{item.note.replace(/(<([^>]+)>)/ig,"")}</a><ReactTooltip className="p-2" id={"mItem" + item.financeManagementItemID} aria-haspopup='true' >{item.note}</ReactTooltip></div>
                                            <div className="text-right">
                                                <img onClick={() => openCloseModal(true,item.financeManagementItemID)} className="edit-icon" src={edit} /> 
                                                <input className="delete-checked" type="checkbox" name="deleteType[]" checked={checked} onChange = {(e) => deleteCheckboxChanged(e,item.financeManagementItemID)} />
                                            </div>
                                        </div>
                                                
                                    )
                                    
                                })} 
                            </Fragment>
                        </div>
                        {itemsData && itemsData.financeManagementItems && itemsData.financeManagementItems.length == 0 &&
                            <NoItems text={translate.t("NoItemsAddedYet")} />
                        }
                        
                        {loading &&  <Loading />}
                    </div>
                    :
                    <Error text={error} />
                }
            </div>
            
            {showAdd &&
                <AddEditFinanceItem openCloseModal={openCloseModal} selectedFinanceManagementItemID={selectedFinanceManagementItemID} currency={props.currency} listVariables={listVariables} refetch={refetch}/>
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delFinanceManagementItems} /> }	
        </Fragment>
    );

}

export default withApollo(FinanceManagementCurrency);