/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit bankAccount
 */
export const ADD_EDIT_BANKACCOUNT = gql`
    mutation AddEditBankAccount($bankAccountID:ID!,$title:String,$accountPrefix:String,$accountNumber:String,$bankNumber:String){
        addEditBankAccount(bankAccountID:$bankAccountID,title:$title,accountPrefix:$accountPrefix,accountNumber:$accountNumber,bankNumber:$bankNumber){
            bankAccountID
            title
            accountPrefix
            accountNumber
            bankNumber
        }
    }
`;


/**
 *  BankAccount data
 */

export const GET_BANKACCOUNT = gql`
    query BankAccount($bankAccountID: ID!){
        bankAccount(bankAccountID:$bankAccountID){
            bankAccountID
            title
            accountPrefix
            accountNumber
            bankNumber            
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
    }
`;

/**
 *  Seznam veškerých bankAccountů
 */
export const GET_BANKACCOUNTS = gql`
    query AllBankAccounts{
        allBankAccounts{            
            bankAccountID
            title
            accountPrefix
            accountNumber
            bankNumber 
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_BANKACCOUNTS = gql`
    mutation deleteBankAccounts($bankAccountIDs:ID!) {
        deleteBankAccounts(bankAccountIDs:$bankAccountIDs)
    }
`;


