/*
    Přidání štítku
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_ARTICLE} from '../Queries/article';
import { withApollo } from 'react-apollo'; 
import ArticleLib from '../Library/article';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import LinkSelector from '../../Content/Components/LinkSelector';
import translate from '../../Translations/index';



registerLocale('cs', cs);

const INITIAL_STATE = {
    apolloError:"",
    selectedArticleID:null,
    mainPhoto:"",
    addArticle:null,
    formData:{
        selectedLang:"cz",
        articleID:0,
        photo:"",
        linkIDs:[],
        langs:[],
    },
    allLanguageMutations:[]
}

class AddEditArticle extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.articleLib = new ArticleLib(this); 
    }

    componentDidMount(){

        this.articleLib.getData();
        
    }
    
    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }
    setSelectedLinks = (linkIDs) => {  
        this.setState({formData:{...this.state.formData,linkIDs}});
    }    

    render(){

        const {apolloError,mainPhoto,allLanguageMutations,selectedArticleID} = this.state;
        var {listVariables} = this.props;


        return(

            <Mutation 
                mutation={ADD_EDIT_ARTICLE}
                update = {async (cache, response) => {

                    this.articleLib.updateList(cache,response,selectedArticleID,listVariables);
                    
                    let notify = new Notification();

                    if(selectedArticleID && selectedArticleID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
                    else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);


                }}
                onCompleted = {(response) => {
                    if(selectedArticleID && selectedArticleID != 0){
                        this.props.openCloseModal(false);
                    }else{
                        
                        this.setState({selectedArticleID:response.addEditArticle.articleID,formData:{...this.state.formData, articleID:response.addEditArticle.articleID}});
                    
                        this.props.openCloseModal(false);
                    }
                    
                }}
                
            >
            {
                (addArticle,{data,loading,error}) => {

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    const {selectedLang,generateRandomRecommended,linkIDs} = this.state.formData;
                    
                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedArticleID && selectedArticleID != 0 ? translate.t("EditArticle") : translate.t("AddArticle"))}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.articleLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {error || apolloError ?
                                        <Error text={error || apolloError} />
                                    :
                                    <div>
                                        
                                        {!loading ?
                                            <Fragment>
                                                
                                                <div>                                                       
                                                    <div className="form-group input-group">
                                                        <div className="custom-file">                                                            
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                name="file"
                                                                required
                                                                multiple
                                                                onChange={(e) => this.articleLib.openImage(e)}
                                                            />
                                                            <label className="custom-file-label" htmlFor="customFile">{translate.t("ChoosePhoto")}</label>
                                                        </div>
                                                    </div>
                                                    
                                                    {mainPhoto &&
                                                        <div className="row">
                                                            <div className="col-12 col-sm-4 mb-4">
                                                                <img className = "maxwidth-100" src={mainPhoto} />
                                                            </div>
                                                            
                                                            <div className="col-12 col-sm-8">
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    <label className="input-label">{translate.t("ArticleTitle")} ({selectedLang})</label>
                                                                                    <div className="form-group">
                                                                                        <input className="form-control" type="text" name="title" value={this.articleLib.getLangValue("title")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    </div>
                                                                                </div>  
                                                                            </div>  
                                                                        </div> 
                                                                    </div> 
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">{translate.t("AddToLink")}</label>
                                                                        <LinkSelector allArticleLinks={linkIDs}  setSelectedLinks={this.setSelectedLinks}/>                                                                            
                                                                    </div>   
                                                                </div>                                                   
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("ArticlePerex")} ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <Editor
                                                                            name="perex"
                                                                            apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                            value={this.articleLib.getLangValue("perex")}
                                                                            init={{
                                                                                plugins: 'link image code',
                                                                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                                                            }}
                                                                            onEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"perex")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="input-label">{translate.t("ArticleText")} ({selectedLang})</label>
                                                                    <div className="form-group">
                                                                        <Editor
                                                                            name="text"
                                                                            apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                            value={this.articleLib.getLangValue("text")}
                                                                            init={{
                                                                                plugins: 'link image code',
                                                                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                                                            }}
                                                                            onEditorChange={(content) => this.articleLib.formLangHandleEditor(content,"text")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="card main">
                                                                    <div className="card-header d-flex align-items-center">
                                                                        {translate.t("SEO")}
                                                                    </div>

                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">{translate.t("MetaTitle")} ({selectedLang})</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="metaTitle" value={this.articleLib.getLangValue("metaTitle")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                </div>
                                                                            </div>  
                                                                            <div className="col-12 col-sm-6">
                                                                                <label className="input-label">{translate.t("MetaKeywords")} ({selectedLang})</label>
                                                                                <div className="form-group">
                                                                                    <input className="form-control" type="text" name="metaKeywords" value={this.articleLib.getLangValue("metaKeywords")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                </div>
                                                                            </div> 
                                                                            <div className="col-12">
                                                                                <label className="input-label">{translate.t("MetaDescription")} ({selectedLang})</label>
                                                                                <div className="input-group">
                                                                                    <input className="form-control" type="text" name="metaDescription" value={this.articleLib.getLangValue("metaDescription")} onChange={(e) => this.articleLib.formLangHandle(e)} />
                                                                                    
                                                                                    <div className="input-group-append">
                                                                                        <span className={"input-group-text " + (this.articleLib.getCharCount("metaDescription") > 160 ? 'red' : '')}>{this.articleLib.getCharCount("metaDescription")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }                                                    
                                                </div>
                                                
  
                                            </Fragment>

                                        :
                                        <Loading />  
                                        }  

                                    </div>
                                    }
               
                                </div>
                                <div className="modal-footer">                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.articleLib.addArticle(addArticle)}>{(selectedArticleID && selectedArticleID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>{translate.t("Close")}</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditArticle);