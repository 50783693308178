/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment, useState } from 'react';
import AddEditBankAccount from './AddEditBankAccount';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_BANKACCOUNTS,DELETE_BANKACCOUNTS} from '../Queries/bankAccount';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import BankAccountLib from '../Library/bankAccount';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';


const BankAccounts = (props) => {
		
	const [selectedBankAccounts,setSelectedBankAccounts] 		    = useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	= useState(false);
	const [showAdd,setShowAdd] 			            = useState(false);
	const [selectedBankAccountID,setSelectedBankAccountID] 		= useState(0);

    const {loading, error:getError, data:bankAccountData} = useQuery(GET_BANKACCOUNTS);
    
	const [deleteBankAccount, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_BANKACCOUNTS,{	
		update(cache,response) {            
            let bankAccount = new BankAccountLib(this);
            bankAccount.updateAfterDelete(cache, response);

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

    const openCloseModal = (open,bankAccountID) => {
        setSelectedBankAccountID(bankAccountID);
        setShowAdd(open);
    }

    /**
     * smazání štítků
     * @param {*} deleteBankAccounts funkce, která posílá data na server
     * @param {*} bankAccountID ID bankAccountu
     */
    
    const initiateDeleteBankAccount = (e,bankAccountID) => {

        var checked = e.target.checked;
        var arr = [...selectedBankAccounts];

        if(!checked){
            for(let i in selectedBankAccounts){
                if(selectedBankAccounts[i] == bankAccountID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [bankAccountID,...arr];
        }

        setSelectedBankAccounts(arr);
  
    }

    const delBankAccount = (action) => {

        if(action){

            let bankAccountIDs = selectedBankAccounts.join(",");

            deleteBankAccount({
                variables:{
                    bankAccountIDs: bankAccountIDs
                }
            })
        }
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedBankAccounts.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,this.props.client);
        }
    }

    
    if(getError || deleteError){
        var error = getError ? getError : deleteError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("BankAccountList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!loading && !error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("BankAccountTitle")}</div>
                                                <div className="text-center">{translate.t("AccountNumber")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {bankAccountData.allBankAccounts && bankAccountData.allBankAccounts.map((item,index) => {

                                                var checked = false;
                                                for(let i in selectedBankAccounts){
                                                    if(selectedBankAccounts[i] == item.bankAccountID)checked = true;
                                                }
                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{item.title}</div>
                                                        <div className="text-center">{(item.accountPrefix ?  item.accountPrefix + "-" : "") + item.accountNumber + (item.bankNumber ?  "/" + item.bankNumber : "")}</div>
                                                        <div className="text-right">
                                                            <img onClick={() => openCloseModal(true,item.bankAccountID)} className="edit-icon" src={edit} /> 
                                                            <input className="delete-checked" type="checkbox" name="deleteBankAccount[]" checked={checked} onChange = {(e) => initiateDeleteBankAccount(e,item.bankAccountID)} />
                                                        </div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {bankAccountData.allBankAccounts && bankAccountData.allBankAccounts.length == 0 &&
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {loading &&  <Loading />}
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditBankAccount openCloseModal={openCloseModal} selectedBankAccountID={selectedBankAccountID} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delBankAccount} /> }	
        </div>
    );

}

export default withApollo(BankAccounts);