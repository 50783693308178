/**
 * soubor se seznamem veškerých graphql dotazů pro login
 */
import gql from 'graphql-tag';



export const LOGIN_USER = gql`
  mutation adminLogin($email: String!,$password: String!) {
    adminLogin(email: $email,password: $password){
        loginToken
        adminUser{
            name
            surname
            email
        }
    }
  } 
`;


