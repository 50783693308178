/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_FINANCEMANAGEMENT_ITEMS,GET_FINANCEMANAGEMENT_ITEMS_RECAP} from '../Queries/financeManagement';
import Notification from '../../../Library/notification';
import translate from '../../Translations/index';
const moment             = require("moment");

class Currency {

    constructor(props){
        this.props = props;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addEditFinanceManagementItem funkce z apollo, pomocí které se posílají data na server
     */

    addEditFinanceManagementItem = async (addEditFinanceManagementItem,formData,addData,callback = null) => {

        let notify = new Notification();   
        if(formData.date && formData.date != ""){ 
            if(formData.value != "" && formData.value != "0"){                
                if(!this.isCurrectyExchangeSelected(addData,formData) || formData.currencyID != formData.exchangeCurrencyID){
                    
                    if(!this.isCurrectyExchangeSelected(addData,formData) || (this.isCurrectyExchangeSelected(addData,formData) && formData.exchangeExchangeRate != "" && formData.exchangeExchangeRate != "0")){ 
                        if(this.isCzechCurrencySelected(addData,formData) || (!this.isCzechCurrencySelected(addData,formData) && formData.exchangeRate != "" && formData.exchangeRate != "0")){ 
                            
                            addEditFinanceManagementItem({
                                variables:{
                                    financeManagementItemID:formData.financeManagementItemID,
                                    currencyID:             formData.currencyID,
                                    date:                   moment(formData.date).format("YYYY-MM-DD"),
                                    typeID:                 formData.typeID,
                                    paymentTypeID:          formData.paymentTypeID,
                                    incomeOutputType:       formData.incomeOutputType,
                                    value:                  parseFloat(formData.value),
                                    exchangeRate:           parseFloat(formData.exchangeRate),
                                    bankAccountID:          formData.bankAccountID,
                                    exchangeExchangeRate:   parseFloat(formData.exchangeExchangeRate),
                                    includeToIncomeOutcome: parseInt(formData.includeToIncomeOutcome),
                                    exchangeCurrencyID:     formData.exchangeCurrencyID,
                                    note:                   formData.note,
                                    createOppositeIncomeOutput: formData.createOppositeIncomeOutput
                                }
                            }).then(responseData => {
                                if(callback){
                                    callback(true,responseData);
                                }
                            }, errorData =>{
                                if(callback){
                                    callback(false,errorData);
                                }
                            });
                        }else{
                            notify.setNotification(null,"Nezadali jste kurz pro převod měny na CZK",false,true,this.props.client);
                        }
                    }else{
                        notify.setNotification(null,"Nezadali jste kurz pro převod měny",false,true,this.props.client);
                    }
                    
                }else{
                    notify.setNotification(null,"Převádíte částku mezi stejnými měnami",false,true,this.props.client);
                }
            }else{
                notify.setNotification(null,"Nezadali jste částku",false,true,this.props.client);
            }
        }else{
            notify.setNotification(null,"Nezadali jste datum",false,true,this.props.client);
        }
               
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    async updateList(cache,response,selectedID,listVariables){
        

        var recapData = await this.props.client.query({ 
            query: GET_FINANCEMANAGEMENT_ITEMS_RECAP,
            errorPolicy:"all",
            variables:listVariables, 
            fetchPolicy: 'network-only'
        });

        const { financeManagementItems } = cache.readQuery({ query: GET_FINANCEMANAGEMENT_ITEMS,variables:listVariables});        

        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditFinanceManagementItem,...financeManagementItems]   ;
            arr.sort(this.sortListFunction);
            console.log(arr);
            cache.writeQuery({ 
                query:GET_FINANCEMANAGEMENT_ITEMS,
                variables:listVariables,
                data:{
                    financeManagementItems: arr,
                    financeManagementItemsRecap: recapData.financeManagementItemsRecap
                } 
            });

        }else{

            var arr = [...financeManagementItems];
            
            arr.forEach((item,index) => {
                
                if(item.financeManagementItemID == response.data.addEditFinanceManagementItem.financeManagementItemID){
                    arr[index] = response.data.addEditFinanceManagementItem;
                }

            });
            arr.sort(this.sortListFunction);
            cache.writeQuery({ 
                query:GET_FINANCEMANAGEMENT_ITEMS,
                variables:listVariables,
                data:{
                    financeManagementItems: arr,
                    financeManagementItemsRecap: recapData.financeManagementItemsRecap
                } 
            });
        }
    }

    sortListFunction(a,b){
        if(moment(a.date).toDate() > moment(b.date).toDate()){
            return 1;
        }else if(moment(a.date).toDate() < moment(b.date).toDate()){
            return -1;
        }else{
            if(a.financeManagementItemID > b.financeManagementItemID){
                return 1;
            }else{
                return -1;
            } 
        }
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    async updateAfterDelete(cache, response,variables){

        
        var recapData = await this.props.client.query({ 
            query: GET_FINANCEMANAGEMENT_ITEMS_RECAP,
            errorPolicy:"all",
            variables:variables, 
            fetchPolicy: 'network-only'
        });

        var resp = response.data.deleteFinanceManagementItems.split(",");

        const { financeManagementItems } = cache.readQuery({ query: GET_FINANCEMANAGEMENT_ITEMS ,variables});
        var arr = [...financeManagementItems];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.financeManagementItemID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_FINANCEMANAGEMENT_ITEMS,
            variables,
            data:{
                financeManagementItems: [...arr],
                financeManagementItemsRecap: recapData.financeManagementItemsRecap
            } 
        });

    }

    
    /**
     * Jestli byl zakliknut druh s parametrem isCurrectyExchange
     * 
     * @param {Object} addData data pro pridani z databaze
     * @param {Object} formData formulářová data
     */
    isCurrectyExchangeSelected(addData,formData){
        if(addData && addData.allTypes){
            for(const item of addData.allTypes){
                if(item.typeID == formData.typeID && item.isCurrencyExchange){
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Jestli byla zakliknuza česká měna
     * 
     * @param {Object} addData data pro pridani z databaze
     * @param {Object} formData formulářová data
     */
    isCzechCurrencySelected(addData,formData){
        if(addData && addData.allCurrencys){
            for(const item of addData.allCurrencys){
                if(item.currencyID == formData.currencyID && item.code == "CZK"){
                    return true;
                }
            }
        }
        return false;
    }
    
    /**
     * Vrátí měnu podle zadané hodnoty (high,low) pro převod měny
     * 
     * @param {String} type jaky typ meny chceme jestli hodnotnejsi nebo slabsi
     * @param {Object} addData data pro pridani z databaze
     * @param {Object} formData formulářová data
     */
    getCodeForCurrencyExchange(type,addData,formData){
        let currencyItem    = null;
        let exchangeCurrecyItem    = null;

        if(addData.allCurrencys){
            for(const item of addData.allCurrencys){
                if(item.currencyID == formData.currencyID ){
                    currencyItem = item;
                }
                if(item.currencyID == formData.exchangeCurrencyID ){
                    exchangeCurrecyItem = item;
                }
            }
        }
        
        if(currencyItem != null && exchangeCurrecyItem != null){
            if(type == "high"){
                if(currencyItem.priority < exchangeCurrecyItem.priority){
                    return currencyItem.code;
                }else{
                    return exchangeCurrecyItem.code;
                }
            }else{
                if(currencyItem.priority > exchangeCurrecyItem.priority){
                    return currencyItem.code;
                }else{
                    return exchangeCurrecyItem.code;
                }
            }
        }
    }


    /** 
     * Vrátí měnu podle zadané hodnoty (high,low) pro převod měny na CZK
     * 
     * @param {String} type jaky typ meny chceme jestli hodnotnejsi nebo slabsi
     * @param {Object} addData data pro pridani z databaze
     * @param {Object} formData formulářová data
     */
    getCodeForCurrencyToCZK(type,addData,formData){        
        let czkItem         = null;
        let currencyItem    = null;
        if(addData.allCurrencys){
            for(const item of addData.allCurrencys){
                if(item.currencyID == formData.currencyID ){
                    currencyItem = item;
                }
                if(item.code == 'CZK' ){
                    czkItem = item;
                }
            }
        }
        
        if(czkItem != null && currencyItem != null){
            if(type == "high"){
                if(czkItem.priority < currencyItem.priority){
                    return czkItem.code;
                }else{
                    return currencyItem.code;
                }
            }else{
                if(czkItem.priority > currencyItem.priority){
                    return czkItem.code;
                }else{
                    return currencyItem.code;
                }
            }
        }
    }
    
}

export default Currency;