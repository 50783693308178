/*
    Přidání štítku
*/
import React,{ Component, Fragment, useState } from 'react';
import {Mutation,Query, useMutation, useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CURRENCY, GET_CURRENCY} from '../Queries/currency';
import { withApollo } from 'react-apollo'; 
import CurrencyLib from '../Library/currency';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';



const AddEditCurrency = (props) => {
    const currencyLib = new CurrencyLib(props);
    
	const [formData,setFormData] 		                    = useState({
        selectedLang:"cz",
        currencyID:props.selectedCurrencyID,
        code:"",
    });
    const [allLanguageMutations,setAllLanguageMutations]    = useState([]);
    
    const {loading:getLoading, error:getError, data:currencyData} = useQuery(GET_CURRENCY,{
        variables:{currencyID:formData.currencyID},
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            setAllLanguageMutations(data.allLanguageMutations);            
            if(formData.currencyID != 0){
                setFormData({...formData,
                    code:data.currency.code,
                });        
            }    
		}
    });
    
	const [addCurrency, { data,loading:addLoading, error: addError }] = useMutation(ADD_EDIT_CURRENCY,{	
		onCompleted(data){
			if(formData.currencyID && formData.currencyID != 0){
                props.openCloseModal(false);
            }else{
                setFormData({...formData,currencyID:data.addEditCurrency.currencyID});

                props.openCloseModal(false);
            }
		},update(cache,response) {            
            currencyLib.updateList(cache,response,formData.currencyID);
            
            let notify = new Notification();

            if(formData.currencyID && formData.currencyID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);
		}
    });

    if(getError || addError){
        var error = getError ? getError : addError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(
                
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-code">{(formData.currencyID && formData.currencyID != 0 ? translate.t("EditCurrency") : translate.t("AddCurrency"))}</h4>
                    {allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => currencyLib.formHandle(e)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                    
                                <div className="row">                                            
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input-label">{translate.t("CurrencyCode")} {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="code" value={formData.code} onChange={(e) => currencyLib.formHandle(e,formData,setFormData)} />
                                                <small id="currencyCodeHelp" className="form-text text-muted">Například: CZK,PLN,EUR,..atd.</small>
                                            </div>
                                        </div>                                                
                                    </div>    
                                </div>                                             

                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => currencyLib.addCurrency(addCurrency,formData)}>{(formData.currencyID && formData.currencyID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>    
    );


}

export default withApollo(AddEditCurrency);