/*
    Přidání štítku
*/
import React,{ Component, Fragment, useState } from 'react';
import {Mutation,Query, useMutation, useQuery} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_TYPE, GET_TYPE} from '../Queries/type';
import { withApollo } from 'react-apollo'; 
import TypeLib from '../Library/type';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import LinkSelector from '../../Content/Components/LinkSelector';
import translate from '../../Translations/index';



const AddEditType = (props) => {
    const typeLib = new TypeLib(props);
    
	const [formData,setFormData] 		                    = useState({
        selectedLang:"cz",
        typeID:props.selectedTypeID,
        title:"",
        isCurrencyExchange:0
    });
    const [allLanguageMutations,setAllLanguageMutations]    = useState([]);
    
    const {loading:getLoading, error:getError, data:typeData} = useQuery(GET_TYPE,{
        variables:{typeID:formData.typeID},
        fetchPolicy: 'cache-and-network',
        onCompleted(data){
            setAllLanguageMutations(data.allLanguageMutations);            
            if(formData.typeID != 0){
                setFormData({...formData,
                    title:data.type.title,
                    isCurrencyExchange:data.type.isCurrencyExchange
                });        
            }    
		}
    });
    
	const [addType, { data,loading:addLoading, error: addError }] = useMutation(ADD_EDIT_TYPE,{	
		onCompleted(data){
			if(formData.typeID && formData.typeID != 0){
                props.openCloseModal(false);
            }else{
                setFormData({...formData,typeID:data.addEditType.typeID});

                props.openCloseModal(false);
            }
		},update(cache,response) {            
            typeLib.updateList(cache,response,formData.typeID);
            
            let notify = new Notification();

            if(formData.typeID && formData.typeID != 0) notify.setNotification(cache,translate.t("SuccessfullySaved"),true,false);
            else notify.setNotification(cache,translate.t("SuccessfullyAdded"),true,false);
		}
    });

    if(getError || addError){
        var error = getError ? getError : addError;
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }

    return(
                
        <Modal
            className="Modal__Bootstrap modal-dialog modal-md"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.openCloseModal(false)}
        >
            <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
                    <h4 className="modal-title">{(formData.typeID && formData.typeID != 0 ? translate.t("EditType") : translate.t("AddType"))}</h4>
                    {allLanguageMutations.length > 1 &&
                        <div className="ml-auto">
                            <select className="form-control" name="selectedLang" onChange={(e) => typeLib.formHandle(e)}>
                                {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                    <option key={index} value={item.suffix}> {item.suffix} </option>
                                ))}
                            </select>
                        </div>
                    }
                    <button type="button" className="close ml-3" onClick={() => props.openCloseModal(false)}>                                    
                        <span aria-hidden="true">x</span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>
                <div className="modal-body">
                    {error ?
                        <Error text={error} />
                        :
                        <div>                            
                            {!getLoading && !addLoading ?
                                    
                                <div className="row">                                            
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input-label">{translate.t("TypeTitle")} {allLanguageMutations.length > 1 && <span>({formData.selectedLang})</span>}</label>
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="title" value={formData.title} onChange={(e) => typeLib.formHandle(e,formData,setFormData)} />
                                            </div>
                                        </div>                                                
                                    </div>
                                    <div className="col-12">
                                        <label className="input-label">{translate.t("IsExchange")}</label>
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isCurrencyExchange" id="isCurrencyExchange1" value="1" checked={(formData.isCurrencyExchange == 1 ?  true : false)} onChange={(e) => typeLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="isCurrencyExchange1">
                                                {translate.t("Yes")}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="isCurrencyExchange" id="isCurrencyExchange2" value="0" checked={(formData.isCurrencyExchange == 0 ?  true : false)} onChange={(e) => typeLib.formHandle(e,formData,setFormData)} />
                                                <label className="form-check-label" htmlFor="isCurrencyExchange2">
                                                {translate.t("No")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                             

                            :
                            <Loading />  
                            }  
                        </div>
                    }
                </div>
                <div className="modal-footer">                                
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => typeLib.addType(addType,formData)}>{(formData.typeID && formData.typeID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.openCloseModal(false)}>{translate.t("Close")}</button>
                </div>
            </div>
        </Modal>    
    );


}

export default withApollo(AddEditType);