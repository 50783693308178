/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * add/edit currency
 */
export const ADD_EDIT_CURRENCY = gql`
    mutation AddEditCurrency($currencyID:ID!,$code:String){
        addEditCurrency(currencyID:$currencyID,code:$code){
            currencyID
            code
        }
    }
`;


/**
 *  Currency data
 */

export const GET_CURRENCY = gql`
    query Currency($currencyID: ID!){
        currency(currencyID:$currencyID){
            currencyID
            code          
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
        }
    }
`;

/**
 *  Seznam veškerých currencyů
 */
export const GET_CURRENCYS = gql`
    query AllCurrencys{
        allCurrencys{            
            currencyID
            code
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_CURRENCYS = gql`
    mutation deleteCurrencys($currencyIDs:ID!) {
        deleteCurrencys(currencyIDs:$currencyIDs)
    }
`;


