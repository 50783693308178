/*
    Úvodní layout administrace
*/
import React,{ Component } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';

import AllArticles from '../Modules/Article/Components/AllArticles.js';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Link from '../Modules/Content/Components/Link';
import Types from '../Modules/Type/Components/Types';
import Logs from '../Modules/Log/Components/Logs';
import BankAccounts from '../Modules/BankAccount/Components/BankAccounts';
import Currencys from '../Modules/Currency/Components/Currencys';
import Overview from '../Modules/Overview/Components/Overview';
import FinanceManagementWrapper from '../Modules/FinanceManagement/Components/FinanceManagementWrapper';


class Layout extends Component {
	
  render() {
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/content/link' component={Link}></Route>
					<Route exact path='/overview' component={Overview}></Route>
					<Route exact path='/finance-management' component={FinanceManagementWrapper}></Route>
					<Route exact path='/lists/type' component={Types}></Route>
					<Route exact path='/log' component={Logs}></Route>
					<Route exact path='/lists/bank-account' component={BankAccounts}></Route>
					<Route exact path='/lists/currency' component={Currencys}></Route>
					<Route exact path='/content/article' component={AllArticles}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					
				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  }
}

export default withAdminAuthentication(Layout);
