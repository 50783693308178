/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../../Public/Images/logo.png';
import emailIcon from '../../../Public/Images/email.svg';
import key from '../../../Public/Images/key.svg';
import {LOGIN_USER} from '../Queries/login';
import {GET_ADMIN_LANGUAGES,ADMIN_USER} from '../../../GlobalQueries/globalQueries';
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation,useQuery } from '@apollo/react-hooks';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import { withApollo } from 'react-apollo';

const Login = (props) => {
			
	const adminUserData = props.client.readQuery({ query:ADMIN_USER });
	
	if(adminUserData && adminUserData.adminUser){		
		props.history.push('/overview');
	}

	const [email,setEmail] 			= useState("");
	const [password,setPassword]	= useState("");
	const [error,setError] 			= useState(null);
	const [lang,setLang] 			= useState('');
  
	
	useEffect(() => {

		const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : "";
		setLang(lang);
	});

	const {data:languageData} = useQuery(GET_ADMIN_LANGUAGES);

	const [adminLogin, { data,loading: loginLoading, error: loginError }] = useMutation(LOGIN_USER,{
		onCompleted(data){
			if(!data.adminLogin.adminUser)setErrorMsg(translate.t("WrongCredentials"));
			else{
				localStorage.setItem('token', data.adminLogin.loginToken);
				localStorage.setItem('loggedUser', data.adminLogin.adminUser.name + " " + data.adminLogin.adminUser.surname);
				localStorage.setItem('loginPlace','3fdc94bf1dd7dccf3d202a768df0a1eb');

				props.client.writeQuery({
					query:ADMIN_USER,
					data: {
						adminUser: {
							name:data.adminLogin.adminUser.name,
							surname:data.adminLogin.adminUser.surname,
							email:data.adminLogin.adminUser.email,
							__typename:"AdminUser"
						},
					},
				});

				props.history.push('/overview');
			}
		},	
		update(cache) {
			cache.writeData({ data: { isLoggedIn: true,loginPlace: "3fdc94bf1dd7dccf3d202a768df0a1eb"} });
		},						
		onError(err){
			const helper = new Helper();
			setErrorMsg(helper.getApolloErrorText(err));
		}
	});

  	const checkLogin = (adminLogin) => {
	    	  	  
		if(password !== '' && email !== ''){
			adminLogin({variables:{email,password}});  
			
		}else{
			setError(translate.t("NotFilledEmailOrPwd"));
			setTimeout(() => setError(''),3000);
		}

	}

	const setErrorMsg = (error) => {
		setError(error);
		setTimeout(() => setError(''),3000);
	}

	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			checkLogin(adminLogin);
		}
	}

	const changeLang = (lang) => {
		translate.locale = lang;
		setLang(lang);
		localStorage.setItem('lang', lang);
	}

    return (
        <div id="login" className="admin">
            <div className="logo">
                <div><img alt={translate.t("SedackyNabytek")} src={logo} /></div>
				<div className="bold">{translate.t("Finance")}</div>
            </div>
        	<div className="panel">
	        	<div className="inner-panel">
	        	    <form >
		        		<div className="form-group">
		        			
		        			<div className="input-group">
		        			  <div className="input-group-prepend">
		        			    <span className="input-group-text" ><img alt="" src={emailIcon} /></span>
		        			  </div>
		        			  <input
							  	  onKeyPress={handleKeyPress}
		        			      onChange={event => setEmail(event.target.value)}
		        			      value={email}
		        			      type="text" className="form-control" placeholder={translate.t("UserName")} aria-label="Username" />
		        			</div>
	
		        		</div>
		        		<div className="form-group">
		        				        			
		        			<div className="input-group">
		        			  <div className="input-group-prepend">
		        			     <span className="input-group-text" ><img alt="" src={key} /></span>
		        			  </div>
		        			  <input
							  	  onKeyPress={handleKeyPress}
		        			      onChange={event => setPassword(event.target.value)}
		        			      value={password}
		        			      type="password" placeholder={translate.t("Password")} className="form-control" aria-label="Password" />
		        			</div>
		        			
		        		</div>
		        		
	        			<div className="form-group">
							{!loginLoading &&
								<div className="d-flex">
									<button type="button" onClick={() => checkLogin(adminLogin)} className="btn btn-primary btn-blue btn-block mr-2">{translate.t("LogIn")}</button>
									{languageData && languageData.allAdminLanguageMutations.length > 1 &&
										<div className="lang-select">									
											<select value={lang} onChange={event => changeLang(event.target.value)} name="lang" className="form-control">										
												{languageData && 
													languageData.allAdminLanguageMutations.map((item,index) => {
														return (
															<option key={index} value={item.suffix}>{item.suffix}</option>
														);
													})
												}
											</select>
										</div>
									}
								</div>
							}
						</div>
		    			{false &&
		    			<div className="text-center">
	        				<Link to='/reset-password'>{translate.t("ForgotYourPwd")}</Link>
	        			</div>
						}
		    			{error && <div className="alert alert-danger mt-4 mb-0">{error}</div>}
	        		</form>
	        		<div className="copy-tag">©2019 1vision</div>
	        	</div>
        	</div>
        </div>
    );
}

export default withApollo(Login);
