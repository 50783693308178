/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment, useState } from 'react';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_LOGS} from '../Queries/log';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import moment from "moment";
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';


const Logs = (props) => {
		
    const [filtersForm,setFiltersForm]          = useState({
        from: moment().toDate(),
        to: moment().toDate()
    });
    const [filterParams,setFiltersParams]       = useState({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        offset:0,
        limit:100
    });

    const {loading, error:getError, data:logData,fetchMore} = useQuery(GET_LOGS,{
        variables:{filters:filterParams},
        fetchPolicy: 'cache-and-network'
    });
    
    const setDateFrom = (date) => {
        setFiltersForm({...filtersForm,from:date});
        setFiltersParams({...filterParams,from:moment(date).format("YYYY-MM-DD")});
    }
    const setDateTo = (date) => {
        setFiltersForm({...filtersForm,to:date});
        setFiltersParams({...filterParams,to:moment(date).format("YYYY-MM-DD")});
    }
    
    useBottomScrollListener(() => {
        if(!loading && logData.allLogsCount > logData.allLogs.length){
            fetchMore({
                variables: {
                  filters: {...filterParams,offset:logData.allLogs.length}
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    allLogs: [...prev.allLogs, ...fetchMoreResult.allLogs]
                  });
                }
            });
        }
    },100, 10);
    
    if(getError){
        const helper = new Helper(); 
        var error = "ERROR: " + helper.getApolloErrorText(getError);
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            Log záznamy
                            <div className="ml-auto d-flex align-items-center">
                                <div className="mr-1">Od:</div> 
                                <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.from} onChange={date => setDateFrom(date)} />
                                <div className="ml-2 mr-1">Do:</div> 
                                <DatePicker className="form-control date-width" dateFormat="dd.MM.yyyy" locale="cs" selected={filtersForm.to} onChange={date => setDateTo(date)}  popperPlacement="top-end"/>
                                <div className="divider mx-2"></div>
                            </div>
                        </div>
                        <div className="card-body">

                            {!error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>Datum záznamu</div>
                                                <div className="text-center">Administrátor</div>
                                                <div>Akce</div>
                                            </div>
                                        </div>
                                        <Fragment>
                                            {logData && logData.allLogs && logData.allLogs.map((item,index) => {                                                                                                                
                                                return (
                                                
                                                    <div key={index} className="data-list-item-content">
                                                        <div>{moment(item.date).format("DD.MM.YYYY HH:mm:ss")}</div>
                                                        <div className="text-center">{item.name} {item.surname}</div>
                                                        <div>{item.text}</div>
                                                    </div>
                                                            
                                                )
                                                
                                            })} 
                                        </Fragment>
                                    </div>
                                    {logData && logData.allLogs && logData.allLogs.length == 0 &&
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    }
                                    {loading &&  <Loading />}
                                    
                                </div>
                                :
                                <Error text={error} />
                            }
                        </div>
                    </div>
                </div>
            </div> 		
        </div>
    );

}

export default withApollo(Logs);