/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';


/**
 *  Seznam veškerých typeů
 */
export const GET_LOGS = gql`
    query AllLogs($filters:LogFiltersInput){
        allLogs(filters:$filters){            
            logID
            date
            text
            name
            surname
        }
        allLogsCount(filters:$filters)
    }
`;

