/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component, Fragment, useState } from 'react';
import AddEditCurrency from './AddEditCurrency';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import { withApollo, useQuery, useMutation } from 'react-apollo';
import {GET_CURRENCYS,DELETE_CURRENCYS,UPDATE_CURRENCY_PRIORITY} from '../Queries/currency';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import edit from '../../../Public/Images/edit.svg';
import NoItems from '../../../GlobalComponents/NoItems';
import CurrencyLib from '../Library/currency';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import move from '../../../Public/Images/move.svg';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const Currencys = (props) => {
		
	const [selectedCurrencys,setSelectedCurrencys] 		= useState([]);
	const [showDeleteNotifi,setShowDeleteNotifi] 	    = useState(false);
	const [showAdd,setShowAdd] 			                = useState(false);
	const [selectedCurrencyID,setSelectedCurrencyID]    = useState(0);

    const {loading, error:getError, data:currencyData, refetch } = useQuery(GET_CURRENCYS,{variables:{orderBy:"priority ASC"},fetchPolicy: 'cache-and-network'});
    
	const [deleteCurrency, { data,loading: deleteLoading, error: deleteError }] = useMutation(DELETE_CURRENCYS,{	
		update(cache,response) {            
            let currency = new CurrencyLib(this);
            currency.updateAfterDelete(cache, response,{orderBy:"priority ASC"});

            let notify = new Notification();
            notify.setNotification(cache,translate.t("SuccessFullyDeleted"),true,false);
            setShowDeleteNotifi(false);
		},						
		onError(err){
            const helper = new Helper(); 
            let notify = new Notification();
            notify.setNotification(null,helper.getApolloErrorText(err),false,true,props.client);
		}
    });

	const [updateCurrencyPriority, { data:updateCurrencyData,loading: priorityUpdateLoading, error: priorityUpdateError }] = useMutation(UPDATE_CURRENCY_PRIORITY,{	
		update(cache,response) {   
            refetch();  
		}
    });

    const openCloseModal = (open,currencyID) => {
        setSelectedCurrencyID(currencyID);
        setShowAdd(open);
    }

    /**
     * smazání štítků
     * @param {*} deleteCurrencys funkce, která posílá data na server
     * @param {*} currencyID ID currencyu
     */
    
    const initiateDeleteCurrency = (e,currencyID) => {

        var checked = e.target.checked;
        var arr = [...selectedCurrencys];

        if(!checked){
            for(let i in selectedCurrencys){
                if(selectedCurrencys[i] == currencyID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [currencyID,...arr];
        }

        setSelectedCurrencys(arr);
  
    }

    const delCurrency = (action) => {

        if(action){

            let currencyIDs = selectedCurrencys.join(",");

            deleteCurrency({
                variables:{
                    currencyIDs: currencyIDs
                }
            })
        }
        //this.setState(INITIAL_STATE);
    }

    const showDelNotifi = () => {

        if(selectedCurrencys.length > 0){
            setShowDeleteNotifi(true);
        }else{
            let notify = new Notification();
            notify.setNotification(null,translate.t("ItemNotSelected"),false,true,this.props.client);
        }
    }

    const onDragEnd = (result) => {

        const IDs = result.draggableId.split("-");

        updateCurrencyPriority({
            variables:{
                currencyID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    
    if(getError || deleteError || priorityUpdateError){
        var error = "";
        if(getError){
            error = getError;
        }else if(deleteError){
            error = deleteError;
        }else if(priorityUpdateError){
            error = priorityUpdateError;
        }
        const helper = new Helper(); 
        error = "ERROR: " + helper.getApolloErrorText(error)
    }
    return(

        <div id="settings" className="whole-container" >
            
            <div className="row" >
                <div className="col-sm one">
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t("CurrencyList")}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => openCloseModal(true,0)}>{translate.t("Add")}</button>
                            <button className="btn btn-danger btn-thiner ml-2" onClick={() => showDelNotifi()}>{translate.t("DeleteSelected")}</button>
                        </div>
                        <div className="card-body">

                            {!loading && !error ?
                                <div>
                                    <div className="data-list">
                                        
                                        <div className="data-list-item header">
                                            <div className="data-list-item-content">
                                                <div>{translate.t("CurrencyCode")}</div>
                                                <div className="text-right">{translate.t("Options")}</div>
                                            </div>
                                        </div>
                                        <DragDropContext onDragEnd={(result) => onDragEnd(result,updateCurrencyPriority)}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {currencyData.allCurrencys && currencyData.allCurrencys.map((item,index) => {

                                                            var checked = false;
                                                            for(let i in selectedCurrencys){
                                                                if(selectedCurrencys[i] == item.currencyID)checked = true;
                                                            }
                                                                                                                            
                                                            return (
                                                                <Draggable key={index} draggableId={item.currencyID} index={index}>
                                                                    {(provided, snapshot) => (
                                                                    <div className="data-list-item" 
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div key={index} className="data-list-item-content">
                                                                            <div><img className="move-icon" src={move} /> {item.code}</div>
                                                                            <div className="text-right">
                                                                                <img onClick={() => openCloseModal(true,item.currencyID)} className="edit-icon" src={edit} /> 
                                                                                <input className="delete-checked" type="checkbox" name="deleteCurrency[]" checked={checked} onChange = {(e) => initiateDeleteCurrency(e,item.currencyID)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    )}
                                                                </Draggable>
                                                                        
                                                            )
                                                            
                                                        })} 
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                    {currencyData.allCurrencys && currencyData.allCurrencys.length == 0 &&
                                        <NoItems text={translate.t("NoItemsAddedYet")} />
                                    }
                                    
                                </div>
                                :
                                <Fragment>
                                    {loading &&  <Loading />}
                                    {error &&  <Error text={error} />}
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div> 	
            {showAdd &&
                <AddEditCurrency openCloseModal={openCloseModal} selectedCurrencyID={selectedCurrencyID} />
            }	
            {showDeleteNotifi && <ModalNotification yesNo={true} text={translate.t("RemoveItems?")} callback={delCurrency} /> }	
        </div>
    );

}

export default withApollo(Currencys);